import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * @param {string} key - The key to store the modal state in localStorage.
 * @param {number} inactivityTimeoutS - The time in seconds to wait before showing the modal after inactivity.
 * @param {number} scrollDepthPercentage - The percentage of the page that must be scrolled before the modal is shown.
 * @param {number} timeBasedActivationS - The time in seconds to wait before showing the modal after the page loads.
 * @returns {[boolean, () => void, () => void]} - An array containing the modal state, a function to open the modal, and a function to close the modal.
 */
export function useLeadsModal({ key, inactivityTimeoutS, scrollDepthPercentage, timeBasedActivationS }) {
  const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);
  const [shouldOpen, setShouldOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined' || isAuthenticated) {
      setShouldOpen(false);
      return;
    }
    const savedState = window.localStorage.getItem(`leadsModal_${key}`);
    setShouldOpen(savedState !== 'true');
  }, [isAuthenticated, key]);

  const handleOpen = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      setShouldOpen(false);
      window.localStorage.setItem(`leadsModal_${key}`, 'true');
    }
  }, [key, isOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setShouldOpen(false);
  }, [key]);

  useEffect(() => {
    if (!shouldOpen || isAuthenticated || typeof window === 'undefined' || !timeBasedActivationS) return;
    const timer = setTimeout(() => {
      handleOpen();
    }, timeBasedActivationS * 1000);

    return () => clearTimeout(timer);
  }, [shouldOpen, isAuthenticated, timeBasedActivationS, handleOpen]);

  useEffect(() => {
    if (!shouldOpen || isAuthenticated || typeof window === 'undefined' || !scrollDepthPercentage) return;

    const handleScroll = () => {
      const docHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercentage = (window.scrollY / docHeight) * 100;
      if (scrollPercentage >= scrollDepthPercentage) {
        handleOpen();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [shouldOpen, isAuthenticated, scrollDepthPercentage, handleOpen]);

  useEffect(() => {
    if (!shouldOpen || isAuthenticated || typeof window === 'undefined' || !inactivityTimeoutS) return;

    let inactivityTimer;
    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        handleOpen();
      }, inactivityTimeoutS * 1000);
    };

    resetTimer();

    const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchmove'];
    events.forEach(event => window.addEventListener(event, resetTimer));

    return () => {
      clearTimeout(inactivityTimer);
      events.forEach(event => window.removeEventListener(event, resetTimer));
    };
  }, [shouldOpen, isAuthenticated, inactivityTimeoutS, handleOpen]);

  return { isOpen, close: handleClose, open: handleOpen };
}
