import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import CloseIcon from '../../assets/icons/close-x.svg';
import * as validators from '../../util/validators';
import { UniversalButton } from '../Button/Button';
import FieldTextInput from '../FieldTextInput/FieldTextInput';

const LeadsModal = ({ open, image, title, description, onSubmit, onClose }) => {
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        sx: {
          position: 'relative',
          borderRadius: 0,
          bgcolor: '#0D241A',
          m: { xs: 2, md: 4 },
          width: { xs: '100%', md: 'auto' },
          maxWidth: { xs: '400px', md: '800px' },
          overflow: 'hidden',
        },
      }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }} width="100%" minHeight={400} justifyContent="center">
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: theme => theme.spacing(1), right: theme => theme.spacing(1) }}
        >
          <Box component="img" src={CloseIcon} alt="Close" width={24} height={24} />
        </IconButton>
        {image && (
          <Box position="relative" display={{ xs: 'none', md: 'block' }} width="50%" height={600}>
            <Box component="img" src={image} alt="Modal image" width="100%" height="100%" sx={{ objectFit: 'cover' }} />
            <Box position="absolute" top={0} left={0} right={0} bottom={0} bgcolor="#0D241A" sx={{ opacity: 0.2 }} />
          </Box>
        )}

        <Stack width={{ xs: '100%', md: '50%' }} p={3} gap={2} direction="column" justifyContent="center">
          {title && (
            <Typography variant="h2" fontWeight={600} fontSize={{ xs: 30, md: 38 }} gutterBottom color="#E9ECEF">
              {title}
            </Typography>
          )}
          {description && (
            <Typography variant="body1" gutterBottom mb={1} color="#E9ECEF">
              {description}
            </Typography>
          )}

          <FinalForm
            onSubmit={values => onSubmit(values.email)}
            render={({ handleSubmit, submitting, invalid }) => (
              <Stack component="form" sx={{ input: { bgcolor: 'white' } }} onSubmit={handleSubmit} gap={2}>
                <FieldTextInput
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  validate={validators.composeValidators(
                    validators.required(intl.formatMessage({ id: 'leads_modal.email_required' })),
                    validators.emailFormatValid(intl.formatMessage({ id: 'leads_modal.invalid_email' }))
                  )}
                />
                <Box display="flex" justifyContent="flex-end">
                  <UniversalButton type="histeric" onClick={handleSubmit} disabled={invalid || submitting}>
                    {intl.formatMessage({ id: 'leads_modal.discount.button' })}
                  </UniversalButton>
                </Box>
              </Stack>
            )}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

LeadsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

LeadsModal.defaultProps = {
  image: null,
  title: '',
  description: '',
};

export default LeadsModal;
