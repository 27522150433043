import { useIntl } from 'react-intl';
import image from '../../assets/leads/two-racers.png';
import { useApiCallback } from '../../hooks/useApi';
import LeadsModal from './LeadsModal';
import { useLeadsModal } from './useLeadsModal';

export const JoinCommunityModal = () => {
  const intl = useIntl();
  const newsletterSubscribeCb = useApiCallback((api, data) => api.newsletterSubscribe(data));
  const modal = useLeadsModal({ key: 'join_community', inactivityTimeoutS: 30, scrollDepthPercentage: 50 });

  return (
    <LeadsModal
      open={modal.isOpen}
      image={image}
      title={intl.formatMessage({ id: 'leads_modal.join_community.title' })}
      description={intl.formatMessage({ id: 'leads_modal.join_community.description' })}
      onSubmit={onSubmit}
      onClose={modal.close}
    />
  );

  function onSubmit(email) {
    newsletterSubscribeCb.execute({ email });
    modal.close();
  }
};
