import { Box, Stack, Paper } from '@mui/material';
import { HorizontalList } from '../HorizontalList';

import VelloccinoLogo from '../../../assets/partners/velloccino.svg';
import PonasDviratisLogo from '../../../assets/partners/ponas_dviratis.svg';
import SSportasLogo from '../../../assets/partners/ssportas.svg';
import AventuraSportLogo from '../../../assets/partners/aventura_sport.svg';
import ArekBikeCenterLogo from '../../../assets/partners/arek_bike_center.svg';

export const SectionPartners = ({ intl }) => (
  <HorizontalList title={intl.formatMessage({ id: 'landing_page.partners' })}>
    {[
      { key: 'velloccino', src: VelloccinoLogo },
      { key: 'ponas_dviratis', src: PonasDviratisLogo },
      { key: 's_sportas', src: SSportasLogo },
      { key: 'aventura_sport', src: AventuraSportLogo },
      { key: 'arek_bike_center', src: ArekBikeCenterLogo },
    ].map(({ key, src }) => (
      <Paper
        key={key}
        elevation={0}
        sx={{
          flex: { xs: 'unset', md: 1 },
          backgroundColor: 'unset'
        }}
      >
        <Stack
          width={{ xs: 120, md: '100%' }}
          height={{ xs: 50, md: 70 }}
          alignItems="center"
          justifyContent="center"
        >
          <Box component="img" src={src} alt={key} px={2} height="auto" width={{ xs: 136, md: '100%' }} />
        </Stack>
      </Paper>
    ))}
  </HorizontalList>
);
